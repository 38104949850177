<template>
  <div class="month-ex">
    <div v-if="!open" class="ant-calendar-picker">
      <a-input v-model="value" @focus="open = true"></a-input><a-icon class="anticon anticon-calendar ant-calendar-picker-icon" type="calendar" @click="handleOpen" />
    </div>
    <a-month-picker placeholder="请选择" class="begin-time" v-model="dateValue" value-format="YYYY-MM" format="YYYY-MM" :open="open" :allowClear="false" v-if="open" @change="handleChange">
      <template slot="renderExtraFooter">
        <div class="today">
          <a-button @click="open = false">关闭</a-button>
          <a-button type="primary" @click="handleNow">至今</a-button>
        </div>
      </template>
    </a-month-picker>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: () => {}
    }
  },
  data() {
    return {
      open: false,
      dateValue: this.value === '至今' ? '' : this.value
    }
  },
  methods: {
    handleNow() {
      this.$emit('input', '至今')
      this.open = false
    },
    handleOpen() {
      this.open = true
    },
    handleChange(val) {
      this.dateValue = val
      this.$emit('input', val)
      this.open = false
    },
    handleBlur(val) {
      this.open = false
    }
  }
}
</script>
<style lang="less" scoped>
.month-ex {
  display: inline-block;
}
.today {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  button {
    width: 48%;
  }
}
</style>
